<template>
  <div class="home">
    <div class="container-home principal">
      <img src="../assets/img/logo_ilcovo.svg" width="350" alt="" />
      <h1 class="title_home">Vivi questa experienza</h1>
    </div>

    <div class="container descubra">
      <div style="height: 10vh"></div>
      <h1 class="title">Venha viver essa experiência.</h1>
      <p class="content">
        Il Covo, que significa o esconderijo em italiano, trará em sua
        identidade a verdadeira sofisticação de um bar exclusivo, com projeto
        minimalista e riqueza na finalização dos revestimentos e dos detalhes.
      </p>
      <h2 class="subTitle" style="margin-top: 5vh">
        Descubra a porta secreta que dá acesso ao Covo bar.
      </h2>
    </div>

    <div class="container dica">
      <div style="height: 10vh"></div>
      <h1 class="title">Dica</h1>
      <p class="content">
        No salão principal, observe as referências de Portofino e acompanhe as
        linhas de iluminação em Led.
      </p>
      <p class="content">
        Repare que, ao fundo, existe uma placa luminosa que destaca outra
        informação.
      </p>
      <p class="content">Vá até ela e encontre uma porta de cervejeira.</p>
    </div>

    <div class="container qr">
      <div style="height: 10vh"></div>
      <h1 class="title" style="font-size: 1.9rem; max-width: 350px">
        Para abrir a porta secreta, aponte o visor abaixo no QR Code
      </h1>
      <qrcode-stream
        @decode="onDecode"
        style="width: 75vw; height: 75vw; margin: 4vh 0"
      ></qrcode-stream>
      <h1 class="subTitle subTitleQR">Pronto, Benvenuto a</h1>
      <img src="../assets/img/logo_ilcovo.svg" width="300" alt="" />
    </div>
    <div class="leitorQR" style="height: 7vh"></div>

    <div
      class="arrow up"
      v-show="this.secaoAtiva != 0"
      @click="praCima"
      style="transform: scaleY(-1); top: 15px; padding-top: 25px"
    >
      <img src="../assets/img/Arrow.svg" width="40" />
    </div>

    <div
      class="arrow down"
      v-show="this.secaoAtiva < this.arrSecoes.length - 1"
      @click="praBaixo"
      style="bottom: 85px; padding-top: 25px"
    >
      <img src="../assets/img/Arrow.svg" width="40" />
    </div>

    <div id="nav">
      <img
        @click="scrollToElm(0)"
        src="../assets/img/Home.svg"
        height="33"
        alt=""
      />
      <img
        @click="scrollToElm(2)"
        src="../assets/img/Lupa.svg"
        height="35"
        alt=""
      />
      <img
        @click="scrollToElm(3)"
        src="../assets/img/Lock.svg"
        height="35"
        alt=""
      />
      <img src="../assets/img/Hnk.svg" width="92" alt="" />
    </div>

    <div v-if="dialog">
      <div class="fundo"></div>
      <div class="dialog">
        <h1 style="font-size: 1.7rem;">Porta liberada!</h1>
        <span @click="dialog = false">Confirmar</span>
      </div>
    </div>
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap");

.fundo {
  background: #000000c7;
  width: 110vw;
  height: 205vw;
  position: fixed;
  bottom: 0;
  z-index: 3;
}

.dialog {
  background-color: white;
  height: 40vh;
  width: 70vw;
  border-radius: 25px;
  position: fixed;
  top: 25%;
  left: 15%;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.dialog span {
  color: #1e88e5;
  font-weight: 600;
}

.dialog::before {
  background-color: black;
  width: 100vw;
  height: 100vw;
  position: fixed;
}

.container-home {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.title_home {
  font-family: "Roboto", sans-serif;
  font-weight: 200;
  color: #fff;
  margin: 0;
}

.title {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 2.7rem;
  color: #ff0000;
  text-shadow: 0px 0px 4px #ff0000;
  margin: 0;
}

.content {
  font-size: 1.6rem;
  font-weight: 300;
  width: 93vw;
  color: #fff;
  text-align: center;
  margin-bottom: 0;
}

.subTitle {
  font-size: 1.7rem;
  font-weight: 300;
  width: 80vw;
  color: #fff;
  text-align: center;
  margin: 0;
}

.qr img {
  margin-bottom: 5vh;
}

.arrow {
  position: fixed;
  z-index: 3;
  right: 0;
  left: 0;
}

#nav {
  height: 70px;
  position: fixed;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 2;
}

@media (max-width: 375px) {
  .title {
    font-size: 2.3rem;
  }
  .content {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
  .subTitle {
    font-size: 1.5rem;
    margin-top: 25px;
  }
  .subTitleQR {
    font-size: 8vw;
    margin: 0;
  }
  .qr img {
    margin-bottom: 7vh;
  }
}
</style>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      secaoAtiva: 0,
      arrSecoes: ["principal", "descubra", "dica", "leitorQR"],
      dialog: false,
    };
  },
  methods: {
    onDecode(decodedString) {
      if (decodedString == "https://zena.cyma.digital/?porta") { 
        fetch("https://hnk00.cyma.digital/scan-porta/7").then(function(res) { 
          this.dialog = true
          // alert("Porta liberada!")
        })
        
      }
    },
    praBaixo() {
      this.secaoAtiva =
        this.secaoAtiva == this.arrSecoes.length - 1
          ? this.arrSecoes.length - 1
          : (this.secaoAtiva = this.secaoAtiva + 1);
      this.scrollToElement();
    },

    praCima() {
      this.secaoAtiva = this.secaoAtiva == 0 ? 0 : --this.secaoAtiva;
      this.scrollToElement();
      if (this.secaoAtiva == 0) {
        this.$emit("scrollToTop");
      }
    },
    scrollToElement() {
      var el = this.$el.getElementsByClassName(
        this.arrSecoes[this.secaoAtiva]
      )[0];

      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },
    scrollToElm(count) {
      this.secaoAtiva = count;
      var el = this.$el.getElementsByClassName(this.arrSecoes[count])[0];

      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },
  },
};
</script>
